<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Quote</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Quote Message List
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <h6 class="mb-0">Quote Message List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="ms-auto text-end">
                        <div class="btn-group">
                            <button type="button" class="btn btn-secondary btn-sm m-1" @click="getExcelStockIn">
                                <i class="bx bxs-plus-square"></i>Export</button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table id="quoteTable" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>City</th>
                                    <th>Address</th>
                                    <th>Message</th>
                                    <th>Project type</th>
                                    <th>Service type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="dataList.length > 0">
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <th>{{index + 1}}</th>
                                    <td>{{data?.name}}</td>
                                    <td>{{data?.email}}</td>
                                    <td>{{data?.phone}}</td>
                                    <td>{{data?.city}}</td>
                                    <td>{{data?.address}}</td>
                                    <td>
                                        <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal"
                                            @click="updateModalValue(data?.message)">
                                            {{ data?.message ? data?.message.slice(0, 30) + '...' : 'No message found.'
                                            }}
                                        </span>
                                    </td>
                                    <td>{{data?.category}}</td>
                                    <td>{{data?.service ? data?.service : "Not Found!"}}</td>
                                    <td>{{this.$filters.formatDateAndYear(data.createdAt)}}</td>
                                    <td>
                                        <select :selected="data?.status" aria-label="Default select example"
                                            v-model="data.status" :onchange=" () => {updateOrder(data);}"
                                            class="form-control">
                                            <!-- <option style="display: none" selected value="">
                                                {{ data.status ? data.status : "Change Status"}}
                                            </option> -->
                                            <option value="Change Status">Change Status</option>
                                            <option value="Contacted">Contacted</option>
                                            <option value="Not Contacted">Not Contacted</option>
                                            <option value="Sold">Sold</option>
                                            <option value="Not Sold">Not Sold</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a data-bs-toggle="modal" data-bs-target="#showDetailsModal"
                                                v-on:click="viewDetails(data)" class="ms-3 bg-primary"><i
                                                    class="bx bxs-show text-white"></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteContact(data?._id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>City</th>
                                    <th>Address</th>
                                    <th>Message</th>
                                    <th>Project type</th>
                                    <th>Service type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Message</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="message"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="showDetailsModal" tabindex="-1" aria-labelledby="showDetailsModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showDetailsModalLabel">Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <table class="table table-bordered">

                            <tbody>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Name</td>
                                    <td>{{ details?.name ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Email</td>
                                    <td>{{details?.email ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Phone</td>
                                    <td>{{details?.phone ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">City</td>
                                    <td>{{details?.city ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Address</td>
                                    <td>{{details?.address ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Categoty</td>
                                    <td>{{ details?.category ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Service</td>
                                    <td>{{ details?.service ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Message</td>
                                    <td>{{ details?.message ?? "Not Found!" }}</td>
                                </tr>
                                <tr>
                                    <td style="min-width: 200px; max-width: 200px;">Message Received</td>
                                    <td>{{this.$filters.formatDate(details.createdAt)}}</td>
                                </tr>
                                <tr>
                                    <td>Message Status</td>
                                    <td>
                                        <select :selected="details.status ?? 'Not Contacted'"
                                            aria-label="Default select example" v-model="details.status"
                                            :onchange=" () => {updateOrder(details);}" class="form-control">
                                            <option style="display: none" selected value="">
                                                {{ details.status ?? "Change Status" }}
                                            </option>
                                            <option value="Change Status">Change Status</option>
                                            <option value="Contacted">Contacted</option>
                                            <option value="Not Contacted">Not Contacted</option>
                                            <option value="Sold">Sold</option>
                                            <option value="Not Sold">Not Sold</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/quote.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>