import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            
            url: this.$serverURL + this.$api.cms.aboutPage.aboutPageFirstSectionCMS,
            cmsData: {
                image: "",
                smallImage: "",
                title: "",
                shortDescription: "",
                longDescription: "",
                coloredText: "",
                point1: "",
                point2: "",
                point3: "",
                point4: "",
                point5: "",
            },
            preview: {
                image: "",
            }
        }
    },
    async created() {
        document.title = "Platinum Landscape - About First Section CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                    
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                this.$swal.showLoading();
                let formData = new FormData();
                formData.append('title', this.cmsData?.title);
                formData.append('shortDescription', this.cmsData?.shortDescription);
                formData.append('longDescription', this.cmsData?.longDescription);
                formData.append('point1', this.cmsData?.point1);
                formData.append('point2', this.cmsData?.point2);
                formData.append('point3', this.cmsData?.point3);
                formData.append('point4', this.cmsData?.point4);
                formData.append('point5', this.cmsData?.point5);
                formData.append('image', this.cmsData?.image);
                formData.append('smallImage', this.cmsData?.smallImage);
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        }
    }
}