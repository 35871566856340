import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            url: this.$serverURL + this.$api.cms.home.aboutSectionCMS,
            cmsData: {
                heading: "",
                title: "",
                description: "",
                buttonText: "",
                buttonLink: "",
                timeTitle: "",
                timeIcon: "",
                serviceTitle: "",
                serviceIcon: "",
                supportTitle: "",
                supportIcon: "",
            },
            preview: {
                timeIcon: "",
                serviceIcon: "",
                supportIcon: "",
            },
            // openingTimeKey: [],
            // openingTimeValue: [],
            // openingTimeArray: {},
            // counter: 1,
        }
    },
    async created() {
        document.title = "Platinum Landscape - About Section CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                console.log("RESPONSE =", response);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                    // if(this.cmsData.openingTime){
                    //     let index = 0;
                    //     this.counter=0;
                    // for (const [key, value] of Object.entries(JSON.parse(this.cmsData.openingTime))) {
                    //     this.openingTimeKey[index] = key;
                    //     this.openingTimeValue[index] = value;
                    //     this.openingTimeArray[this.openingTimeKey[index]] = this.openingTimeValue[index];
                    //     index++;
                    //     this.counter = this.counter + 1;
                    // }
                    // }
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                // if (!this.finalOpeningTime()) {
                //     return;
                // }
                // this.openingTimeArray = {};
                //     for (let i = 0; i < this.counter; i++) {
                //         this.openingTimeArray[i] = this.openingTimeValue[i];
                //     }
                this.$swal.showLoading();
                let formData = new FormData();
                formData.append('heading', this.cmsData?.heading);
                formData.append('title', this.cmsData?.title);
                formData.append('description', this.cmsData?.description);
                formData.append('buttonText', this.cmsData?.buttonText);
                formData.append('buttonLink', this.cmsData?.buttonLink);
                formData.append('timeTitle', this.cmsData?.timeTitle);
                formData.append('timeIcon', this.cmsData?.timeIcon);
                formData.append('serviceTitle', this.cmsData?.serviceTitle);
                formData.append('serviceIcon', this.cmsData?.serviceIcon);
                formData.append('supportTitle', this.cmsData?.supportTitle);
                formData.append('supportIcon', this.cmsData?.supportIcon);
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        // addOpeningTime() {
        //     let index = this.counter - 1;
        //     if (!this.openingTimeValue[index]) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter a time!"
        //         });
        //         return;
        //     }
        //     this.counter = this.counter + 1;
        // },
        // finalOpeningTime() {
        //     let index = this.counter - 1;
        //     if (!this.openingTimeValue[index]) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter time!"
        //         });
        //         return false;
        //     }
        //     return true;
        // },
        // deleteOpeningTimeRow(index) {
        //     delete this.openingTimeArray[this.openingTimeValue[index]];
        //     this.openingTimeValue.splice(index, 1);
        //     this.counter = this.counter - 1;
        // },

    }
}