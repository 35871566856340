import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.services.list,
            singleProduct: {},
            isFeatured: false,
            category: {},
            categoryList: [],
            previewFeaturedImage: "",
            featuredImageFile: "",
            iconFile: "",
            previewIcon: ""
        }
    },
    async mounted() {
        await this.getCategoryList();
        await this.getServiceDetails();
    },
    methods: {
        getServiceDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    console.log("response.data.data", response.data.data);
                    if (response.data.statusCode == 200) {
                        this.singleProduct = response.data.data;
                        if(this.singleProduct.isFeatured) {
                            this.isFeatured= this.singleProduct.isFeatured
                        } 
                        this.category = {
                            _id: this.singleProduct.category._id,
                            title: this.singleProduct.category.title,
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });
            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage(event) {
            this.featuredImageFile = event.target.files[0];
            let input = this.$refs.featuredImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
        uploadIcon(event) {
            this.iconFile = event.target.files[0];
            let input = this.$refs.iconInput;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewIcon = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        getCategoryList: async function () {
            let url = this.$serverURL + this.$api.category.categoryAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!this.singleProduct.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service title!"
                });
                return false;
            }
            if (!this.singleProduct.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service description!"
                });
                return false;
            }
            if (!this.singleProduct.canonicalUrl) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service cannonicalurl!"
                });
                return false;
            }
            if (!(this.categoryList?.find((cat)=> { 
                return cat._id == this.category?._id}))) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category!"
                });
                return false;
            }
            return true;
        },
        updateService: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("title", this.singleProduct.title);
                    formData.append("description", this.singleProduct.description);
                    formData.append("canonicalUrl", this.singleProduct.canonicalUrl);
                    formData.append("metaTitle", this.singleProduct.metaTitle);
                    formData.append("metaDescription", this.singleProduct.metaDescription);
                    formData.append("metaKeyword", this.singleProduct.metaKeyword);
                    formData.append("featuredImage", this.featuredImageFile ? this.featuredImageFile : this.singleProduct.featuredImage);
                    formData.append("featuredImageTitle", this.singleProduct.featuredImageTitle);
                    formData.append("featuredImageAlt", this.singleProduct.featuredImageAlt);
                    formData.append("icon", this.iconFile ? this.iconFile : this.singleProduct.icon);
                    formData.append("iconTitle", this.singleProduct.iconTitle);
                    formData.append("iconAlt", this.singleProduct.iconAlt);
                    formData.append("categoryId", this.category._id);
                    formData.append("isFeatured", this.isFeatured ? true : false);
                    let config = {
                        method: 'PATCH',
                        url: this.siteUrl + '/' + this.id,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$swal.hideLoading();
                    this.$router.push("/service-list");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        }
    }
}