import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.blog.blogAPI,
            singleAPI: this.$serverURL + this.$api.blog.singleBlogAPI,
            title: "",
            authorName: "",
            authorImage:"",
            image: "",
            previewImage: "",
            previewAuthorImage: "",
            blogDescription: "",
            publishedAt: "",
        }

    },
    async mounted() {
    },
    methods: {
        uploadBlogPhoto: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadAuthorPhoto: function (event) {
            this.authorImage = event.target.files[0];
            let input = this.$refs.authorImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewAuthorImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog title."
                })
                return false;
            } else if (!this.authorName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog author name."
                })
                return false;
            } else if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog image."
                })
                return false;
            } else if (!this.blogDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog description."
                })
                return false;
            }else if (!this.publishedAt) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog publication date."
                })
                return false;
            }
            return true;
        },
        addBlogPost: async function () {
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append('title', this.title)
                    formData.append('authorName', this.authorName);
                    formData.append('authorImage', this.authorImage);
                    formData.append('image', this.image);
                    formData.append('blogDescription', this.blogDescription);
                    formData.append('publishedAt', this.publishedAt);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$router.push("/blog-post");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        },
    }
}