<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Category</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add New Category
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Category</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="mb-3">
                      <label for="inputCategoryTitle" class="form-label">Title</label>
                      <input type="text" class="form-control" id="inputCategoryTitle" placeholder="" v-model="title" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <label class="form-label" for="description">Description:</label>
                        <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Icon: <strong>[Preferred Logo Size: 1440X2114, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="icon" id="icon" ref="icon"
                        aria-describedby="imageHelp" accept="image/*" @change="uploadcategoryIcon" />
                      <img v-if="previewIcon" :src="previewIcon" height="80" />
                      <img v-else src="/core/assets/images/400.png" height="80" />
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <button type="button" @click="addcategory" class="btn btn-primary">
                          Save category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script src='../js/add-category.js'></script>