<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Robots Txt</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Robots Txt</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-body">
            <div class="row">
              <div class="col-lg-6">
                <textarea type="text" v-model="robotsTxt" class="form-control" placeholder="Robots Txt" rows="12"/>
                <div class="mt-4 d-grid">
                  <button type="button" @click="update()" class="btn btn-primary">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/robots-txt.js">
</script>
