<template>
    <div class="sidebar-wrapper" data-simplebar="true">
        <div class="sidebar-header">
            <div>
                <img src="../../../../public/core/assets/logo.png" style="width: 60px;" class="logo-icon" alt="logo icon" />
            </div>
            <div>
                <h4 class="logo-text" style="font-size: 18px;">Platinum Landscape</h4>
            </div>
            <div class="toggle-icon ms-auto">
                <i class="bx bx-arrow-to-left"></i>
            </div>
        </div>
        <ul class="metismenu" id="menu">
            <li>
                <a href="javascript:void(0)" @click="$router.push('/home')">
                    <div class="parent-icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <div class="menu-title">Dashboard</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/category')">
                    <div class="parent-icon">
                        <i class='bx bxs-customize'></i>
                    </div>
                    <div class="menu-title">Category</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/service-list')">
                    <div class="parent-icon">
                        <i class='bx bxs-dice-5'></i>
                    </div>
                    <div class="menu-title">Services</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/project-list')">
                    <div class="parent-icon">
                        <i class='bx bxs-image-add' ></i>
                    </div>
                    <div class="menu-title">Project</div>
                </a>
            </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/location-list')">
              <div class="parent-icon">
                <i class='bx bxs-location-plus' ></i>
              </div>
              <div class="menu-title">Location</div>
            </a>
          </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/testimonial')">
                    <div class="parent-icon">
                        <i class='bx bxs-book-reader'></i>
                    </div>
                    <div class="menu-title">Testimonial</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/team-members')">
                    <div class="parent-icon">
                        <i class='bx bxs-contact'></i>
                    </div>
                    <div class="menu-title">Team Members</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/quotes')">
                    <div class="parent-icon">
                        <i class='bx bxs-pen'></i>
                    </div>
                    <div class="menu-title">Quotes</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter')">
                    <div class="parent-icon">
                        <i class='bx bxs-news' ></i>
                    </div>
                    <div class="menu-title">Subscriber</div>
                </a>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class='bx bxs-traffic-barrier'></i>
                    </div>
                    <div class="menu-title">Design Processes</div>
                </a>
                <ul>
                            <li>
                                <a href="/design-processes"
                                    @click.prevent="$router.push('/design-processes')">
                                    <i class="bx bx-right-arrow-alt"></i>Commercial Design Process
                                </a>
                            </li>
                            <li>
                                <a href="/residential-design-processes"
                                    @click.prevent="$router.push('/residential-design-processes')">
                                    <i class="bx bx-right-arrow-alt"></i>Residential Design Process
                                </a>
                            </li>
                        </ul>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class='bx bxs-cog'></i>
                    </div>
                    <div class="menu-title">CMS Settings</div>
                </a>
                <ul>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class="fa-solid fa-home"></i>
                            </div>
                            Home Page
                        </a>
                        <ul>
                            <!-- <li>
                                <a href="/sliders" @click.prevent="$router.push('/sliders')">
                                    <i class="bx bx-right-arrow-alt"></i>Hero Section Slider
                                </a>
                            </li> -->
                            <li>
                                <a href="/hero-section" @click.prevent="$router.push('/hero-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Hero Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/sliders" @click.prevent="$router.push('/about-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>About Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/sliders" @click.prevent="$router.push('/our-services-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Our Services Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/sliders" @click.prevent="$router.push('/popular-services-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Popular Services Section CMS
                                </a>
                            </li>
                          <li>
                            <a href="/sliders" @click.prevent="$router.push('/why-choose-section-cms')">
                              <i class="bx bx-right-arrow-alt"></i>Why Choose Section CMS
                            </a>
                          </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bxs-info-circle'></i>
                            </div>
                            About Page
                        </a>
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/about-page-banner-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Page Banner CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/about-page-first-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>First Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/about-page-journey-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Journey Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/about-page-our-team-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Our Team Section CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bxs-buildings'></i>
                            </div>
                            Residential Page
                        </a>
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/residential-page-banner-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Page Banner CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/residential-page-first-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>First Section Cms
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/residential-page-middle-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Middle Section Cms
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/residential-page-service-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Service Section Cms
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bxs-building'></i>
                            </div>
                            Commercial Page
                        </a>
                        <ul>
                            <li>
                                <a href="/commercial-page-banner-cms" @click.prevent="$router.push('/commercial-page-banner-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Page Banner CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/commercial-page-middle-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Middle Section Cms
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click.prevent="$router.push('/commercial-page-service-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Service Section Cms
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bxs-copy'></i>
                            </div>
                            Pop Up CMS
                        </a>
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/welcome-popup-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Welcome Popup CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/thank-you-popup-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Thank You Popup CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bxs-brush' ></i>
                            </div>
                            Design Process CMS
                        </a>
                        <ul>
                            <li>
                                <a href="/design-process-section-cms"
                                    @click.prevent="$router.push('/design-process-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Commercial Design Process Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/residential-design-process-section-cms"
                                    @click.prevent="$router.push('/residential-design-process-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Residential Design Process Section CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bxs-chess'></i>
                            </div>
                            All Common CMS
                        </a>
                        <ul>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/project-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Project Section Common CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/create-environment-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Create Environment Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/quote-form-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Quote Form Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/statistics-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Statistics CMS
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="$router.push('/explore-our-services-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Explore Our Services CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <!-- <li>
                        <a href="javascript:void(0)" @click="$router.push('/experience-section-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Experience Section CMS
                        </a>
                    </li> -->
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/testimonial-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Testimonial CMS
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/social-links')">
                            <i class="bx bx-right-arrow-alt"></i>Social Links
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/header-footer-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Header Footer CMS
                        </a>
                    </li>
                    <!-- <li>
                        <a href="/contact-page-cms" @click.prevent="$router.push('/contact-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
                        </a>
                    </li> -->
                    <li>
                        <a href="/privacy-policy-page-cms" @click.prevent="$router.push('/privacy-policy-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/terms-and-condition-page-cms"
                            @click.prevent="$router.push('/terms-and-condition-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Terms & Condition Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/quote-banner-section-cms"
                            @click.prevent="$router.push('/quote-banner-section-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Quote Banner Section CMS
                        </a>
                    </li>
                    <li>
                        <a href="/project-page-banner-cms"
                            @click.prevent="$router.push('/project-page-banner-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Project Page  CMS
                        </a>
                    </li>
                    <li>
                        <a href="/contact-page-banner-cms"
                            @click.prevent="$router.push('/contact-page-banner-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Contact Page Banner CMS
                        </a>
                    </li>
                </ul>
            </li>

            <!-- SEO Settings -->
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class='bx bxs-cog'></i>
                    </div>
                    <div class="menu-title">SEO Settings</div>
                </a>
                <ul>
                    <li>
                        <a href="/page-title-cms" @click.prevent="$router.push('/page-title-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Page Titles CMS
                        </a>
                    </li>
                  <li>
                    <a href="/robots-txt" @click.prevent="$router.push('/robots-txt')">
                      <i class="bx bx-right-arrow-alt"></i>Robots Txt
                    </a>
                  </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script setup lang="ts">
</script>
