
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.home.heroSection.cms,
            cmsData: {
                id: "",
                heading: "",
                title: "",
                description: "",
                buttonText: "",
                buttonLink: "",
                backgroundImage: "",
            },
            image: "",
            video: "",
            preview: {
                backgroundImage: "",
                video: "",
                image: ""
            },
            uploadType: "image",
            allowedVideoType: ['mp4', 'avi', 'mkv'],
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
        }
    },
    async created() {
        document.title = "Platinum Landscape- Hero Section CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};

                    const extenstion = this.cmsData?.media?.split('.').pop();
                    if (extenstion === 'mp4' || extenstion === 'avi' || extenstion === 'mkv') {
                        this.uploadType = "video"
                    } else this.uploadType = "image";

                    if (this.uploadType === "image") {
                        this.image = this.cmsData?.media;
                        this.preview.image = this.cmsData?.media;
                    } else if (this.uploadType === "video") {
                        this.video = this.cmsData?.media;
                        this.preview.video = this.cmsData?.media;
                    }

                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                let formData = new FormData();
                formData.append('heading', this.cmsData?.heading);
                formData.append('title', this.cmsData?.title);
                formData.append('description', this.cmsData?.description);
                formData.append('buttonText', this.cmsData?.buttonText);
                formData.append('buttonLink', this.cmsData?.buttonLink);
                formData.append('newsletterTitle', this.cmsData?.newsletterTitle);
                formData.append('backgroundImage', this.cmsData?.backgroundImage);
                if (this.uploadType === 'image') {
                    formData.append('media', this.image);
                } else if (this.uploadType === 'video') {
                    formData.append('media', this.video);
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadMediaImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview.image = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadVideo(videoKey, previewVideoKey, ref, event) {
            const filetype = event.target.files[0].type.split('/').pop();
            if (!this.allowedVideoType.includes(filetype)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Video should be in avi/mkv/mp4 format!"
                })
                this.preview[previewVideoKey] = '';
                this[videoKey] = '';
                return false;
            }
            this.video = '';
            this.preview.video = '';
            this[videoKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.preview[previewVideoKey] = e.target.result;
                }
                reader.readAsDataURL(file[0]);
            }
        },
    }
}