import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "Quote",
    data() {
        return {
            setURL: '',
            name:'',
            email:'',
            phone:'',
            message:'',
            details : {},
            sheets: []
        }
    },
    async created() {
        document.title = "Platinum Landscape - Quotes";
        this.setURL = this.$serverURL + this.$api.quote.quoteAPI;
       await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
            "https://cdn.datatables.net/plug-ins/1.10.11/sorting/date-eu.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getExcelStockIn: async function () {
            if(this.dataList.length > 0){
                this.dataList.map((el) => {
                    this.sheets.push({
                        'Name': el?.name ?? "N/A",
                        'Email': el?.email ?? "N/A",
                        'Phone': el?.phone ?? "N/A",
                        'City': el?.city ?? "N/A",
                        'Message': el?.message ?? "N/A",
                        'Project type': el?.category ?? "N/A",
                        'Service type': el?.service ?? "N/A",
                        'Date': this.$filters.formatDateAndYear(el.createdAt),
                        'Status': el?.status ?? "N/A",
                    });
                })
                const data = XLSX.utils.json_to_sheet(this.sheets)
                const wb = XLSX.utils.book_new()
                const date = new Date().toLocaleString();
                XLSX.utils.book_append_sheet(wb, data, 'data')
                XLSX.writeFile(wb,'quote-list('+date+').xlsx')
            }
            else {
                this.$swal.fire({
                    icon: "error",
                    text: "Quote In list is empty!"
                });
            }
        },
        async updateModalValue(message) {
            this.message = message;
        },
        viewDetails (data){
           this.details = data
        },
        async updateOrder(data) {
            try {
                const datas ={
                    status: data.status,
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.quote.quoteAPI + '/' + data._id,
                    data: datas,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.postDataToBackend(config);
                await this.getDataList(this.setURL)
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        deleteContact: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: this.setURL + '/' + id,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);
                            
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                })
            
        }
    }
}