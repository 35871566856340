<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Commercial Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Service Section CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Title:</label>
                                    <input type="text" v-model="cmsData.title" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Image One:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.imageOne" :src="preview.imageOne"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.imageOne"
                                            :src="cmsData.imageOne" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="imageOne" accept="image/*"
                                            @change="handleUploadImage('imageOne', 'imageOne', 'imageOne', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Image Two:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.imageTwo" :src="preview.imageTwo"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.imageTwo"
                                            :src="cmsData.imageTwo" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="imageTwo" accept="image/*"
                                            @change="handleUploadImage('imageTwo', 'imageTwo', 'imageTwo', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Button Text:</label>
                                    <input type="text" v-model="cmsData.buttonText" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Button Link:</label>
                                    <input type="text" v-model="cmsData.buttonLink" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/commercial-page-service-section-cms.js"></script>