import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: this.$serverURL + this.$api.services.list,

            title: "",
            description: "",
            service:{
                featuredImage: "",
                icon:"",
            },
            isFeatured: false,
            preview: {
                featuredImage: "",
                icon:"",
            },
            category: {},
            categoryList: [],
            canonicalUrl : "",
            metaKeyword : "",
            metaDescription : "",
            metaTitle : "",
            featuredImageTitle : "",
            featuredImageAlt : "",
            iconTitle : "",
            iconAlt : ""
        }

    },
    async mounted() {
        await this.getCategoryList();
    },
    methods: {
        uploadFeaturedImage(imageKey, previewImageKey, ref, event) {
            this.service[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getCategoryList: async function () {
            let url = this.$serverURL + this.$api.category.categoryAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service title!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service description!"
                });
                return false;
            }
            if (!this.service.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            }
            if (!this.service.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter icon!"
                });
                return false;
            }
            if (!(this.categoryList?.find((cat)=> { 
                return cat._id == this.category?._id}))) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category!"
                });
                return false;
            }
            return true;
        },
        addService: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("title", this.title);
                    formData.append("description", this.description);
                    formData.append("canonicalUrl", this.canonicalUrl);
                    formData.append("metaKeyword", this.metaKeyword);
                    formData.append("metaDescription", this.metaDescription);
                    formData.append("metaTitle", this.metaTitle);
                    formData.append("featuredImage", this.service.featuredImage);
                    formData.append("featuredImageTitle", this.featuredImageTitle);
                    formData.append("featuredImageAlt", this.featuredImageAlt);
                    formData.append("icon", this.service.icon);
                    formData.append("iconTitle", this.iconTitle);
                    formData.append("iconAlt", this.iconAlt);
                    formData.append("categoryId", this.category._id);
                    formData.append("isFeatured", this.isFeatured ? true : false);

                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$swal.hideLoading();
                    this.$router.push("/service-list");
                }   catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        }
    },
}