<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Our Services Section CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Title:</label>
                                    <input type="text" v-model="cmsData.title" class="form-control">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="border border-3 p-4 rounded mb-3">
                            <div class="row mb-3" v-for="index in counter" :key="index">
                                <div class="col-sm-6">
                                    <label class="form-label">Opening Time:</label>
                                    <div class="d-flex gap-2">
                                        <input type="text" class="form-control" v-model="openingTimeValue[index - 1]" />
                                        <button v-if="counter > 1" @click="deleteOpeningTimeRow(index - 1)" type="button"
                                            class="btn btn-danger">X</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <button type="button" @click="addOpeningTime()" class="btn btn-primary">Add More</button>
                            </div>
                        </div> -->
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.icon" :src="preview.icon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.icon"
                                            :src="cmsData.icon" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="icon" accept="image/*"
                                            @change="handleUploadImage('icon', 'icon', 'icon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/our-services-section-cms.js"></script>