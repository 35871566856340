import { authHeader } from '@/auth';
import TinyMceTextEditor from '@/components/common/TinyMceTextEditor/template/TinyMceEditor'

export default {
  components: {
    TinyMceTextEditor
  },
  data() {
    return {
      setURL: this.$serverURL + this.$api.locations.list,
      location: {
        locationName: "",
        title: "",
        shortDescription: "",
        description: "",
        image: "",
        imageAlt: "",
        imageTitle: "",
        officeHoursTitle: "",
        officeHoursSaturday: "",
        officeHoursSunday: "",
        officeHoursMonday: "",
        officeHoursTuesday: "",
        officeHoursWednesday: "",
        officeHoursThursday: "",
        officeHoursFriday: "",
        locationTitle: "",
        locationAddress: "",
        locationPhone: "",
        latitude: "",
        longitude: "",
        faqTitle: "",
        metaTitle: "",
        metaKeyword: "",
        metaDescription: "",
        canonicalUrl: "",
        seoIndex: true,
        slug: "",
      },
      preview: {
        image: "",
      },
      faq: [
        {question: "", answer: ""},
      ],
    }
  },
  methods: {
    handleAddFaq() {
      this.faq.push({question: "", answer: ""},)
    },
    removeFaqItems(i) {
      this.faq.splice(i, 1)
    },
    uploadFeaturedImage(imageKey, previewImageKey, ref, event) {
      this.location[imageKey] = event.target.files[0];
      let input = this.$refs[ref];
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.preview[previewImageKey] = e.target.result
        }
        reader.readAsDataURL(file[0])
      }
    },
    checkValidation: function () {
      if (!this.location.locationName) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter location name!"
        });
        return false;
      }
      if (!this.location.title) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter location title!"
        });
        return false;
      }
      if (!this.location.shortDescription) {
        this.$swal.fire({
          icon: "error",
          text: "Please enter location short description!"
        });
        return false;
      }
      for (let index = 0; index < this.faq.length; index++) {
        if (this.faq[index]['question'] === '' || this.faq[index]['answer'] === '') {
          this.$swal.fire({
            icon: "error",
            text: "Faq question or answer cannot be empty!"
          });
          return false;
        }
      }
      return true;
    },
    addLocation: async function () {
      if (this.checkValidation()) {
        try {
          this.$swal.showLoading();
          let formData = new FormData();
          formData.append("locationName", this.location.locationName);
          formData.append("title", this.location.title);
          formData.append("shortDescription", this.location.shortDescription);
          formData.append("description", this.location.description);
          formData.append("image", this.location.image);
          formData.append("imageAlt", this.location.imageAlt);
          formData.append("imageTitle", this.location.imageTitle);
          formData.append("officeHoursTitle", this.location.officeHoursTitle);
          formData.append("officeHoursSaturday", this.location.officeHoursSaturday);
          formData.append("officeHoursSunday", this.location.officeHoursSunday);
          formData.append("officeHoursMonday", this.location.officeHoursMonday);
          formData.append("officeHoursTuesday", this.location.officeHoursTuesday);
          formData.append("officeHoursWednesday", this.location.officeHoursWednesday);
          formData.append("officeHoursThursday", this.location.officeHoursThursday);
          formData.append("officeHoursFriday", this.location.officeHoursFriday);
          formData.append("locationTitle", this.location.locationTitle);
          formData.append("locationAddress", this.location.locationAddress);
          formData.append("locationPhone", this.location.locationPhone);
          formData.append("latitude", this.location.latitude);
          formData.append("longitude", this.location.longitude);
          formData.append("faqTitle", this.location.faqTitle);
          formData.append("metaTitle", this.location.metaTitle);
          formData.append("metaKeyword", this.location.metaKeyword);
          formData.append("metaDescription", this.location.metaDescription);
          formData.append("canonicalUrl", this.location.canonicalUrl);
          formData.append("seoIndex", this.location.seoIndex ? 'yes' : 'no');
          formData.append("slug", this.location.slug);
          if (this.faq) {
            for (const faqKey in this.faq) {
              formData.append(`faq[${faqKey}][question]`, this.faq[faqKey].question);
              formData.append(`faq[${faqKey}][answer]`, this.faq[faqKey].answer);
            }
          }

          let config = {
            method: 'POST',
            url: this.setURL,
            data: formData,
            headers: {
              "Authorization": authHeader(),
              "Content-Type": "multipart/form-data",
            }
          };
          await this.postDataToBackend(config);
          this.$swal.hideLoading();
          this.$router.push("/location-list");
        } catch (error) {
          this.$swal.hideLoading();
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again! " + error
          });
        }
      }
    }
  },
}
