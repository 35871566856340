import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            setURL: '',
            pageCMSData: {
                homePageTitle: "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                homePageCanonicalUrl : "",
                aboutPageTitle: "",
                aboutPageMetaKeyword: "",
                aboutPageMetaDescription: "",
                aboutPageCanonicalUrl : "",
                commercialPageTitle: "",
                commercialPageMetaKeyword: "",
                commercialPageMetaDescription: "",
                commercialPageCanonicalUrl : "",
                residentialPageTitle: "",
                residentialPageMetaKeyword: "",
                residentialPageMetaDescription: "",
                residentialPageCanonicalUrl : "",
                projectPageTitle: "",
                projectPageMetaKeyword: "",
                projectPageMetaDescription: "",
                projectPageCanonicalUrl : "",
                contactPageTitle: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                contactPageCanonicalUrl : "",
                termsPageTitle: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
                termsPageCanonicalUrl : "",
                privacyPageTitle: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                privacyPageCanonicalUrl : "",
            }
        }
    },
    async mounted() {
        document.title = "Platinum Landscape - Page Title";
        this.setURL = this.$serverURL + this.$api.seo.pageTitles;
        let response = await this.getCmsData(this.setURL);
        console.log(response);
        if (response.status == 200) {
            this.pageCMSData = (response.data.data ? response.data.data : {});
        }
        if (this.pageCMSData) {
            console.log("pageCMSData", this.pageCMSData);
        } else {
            this.pageCMSData = {
                homePageTitle: "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                homePageCanonicalUrl : "",
                aboutPageTitle: "",
                aboutPageMetaKeyword: "",
                aboutPageMetaDescription: "",
                aboutPageCanonicalUrl : "",
                commercialPageTitle: "",
                commercialPageMetaKeyword: "",
                commercialPageMetaDescription: "",
                commercialPageCanonicalUrl : "",
                residentialPageTitle: "",
                residentialPageMetaKeyword: "",
                residentialPageMetaDescription: "",
                residentialPageCanonicalUrl : "",
                projectPageTitle: "",
                projectPageMetaKeyword: "",
                projectPageMetaDescription: "",
                projectPageCanonicalUrl : "",
                contactPageTitle: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                contactPageCanonicalUrl : "",
                termsPageTitle: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
                termsPageCanonicalUrl : "",
                privacyPageTitle: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                privacyPageCanonicalUrl : "",
            };
        }
    },
    methods: {
        async update() {
            this.$swal.showLoading();
            try {
                let data = {
                    homePageTitle: this.pageCMSData.homePageTitle,
                    homePageMetaKeyword: this.pageCMSData.homePageMetaKeyword,
                    homePageMetaDescription: this.pageCMSData.homePageMetaDescription,
                    homePageCanonicalUrl : this.pageCMSData.homePageCanonicalUrl,
                    aboutPageTitle: this.pageCMSData.aboutPageTitle,
                    aboutPageMetaKeyword: this.pageCMSData.aboutPageMetaKeyword,
                    aboutPageMetaDescription: this.pageCMSData.aboutPageMetaDescription,
                    aboutPageCanonicalUrl : this.pageCMSData.aboutPageCanonicalUrl,
                    commercialPageTitle: this.pageCMSData.commercialPageTitle,
                    commercialPageMetaKeyword: this.pageCMSData.commercialPageMetaKeyword,
                    commercialPageMetaDescription: this.pageCMSData.commercialPageMetaDescription,
                    commercialPageCanonicalUrl : this.pageCMSData.commercialPageCanonicalUrl,
                    residentialPageTitle: this.pageCMSData.residentialPageTitle,
                    residentialPageMetaKeyword: this.pageCMSData.residentialPageMetaKeyword,
                    residentialPageMetaDescription: this.pageCMSData.residentialPageMetaDescription,
                    residentialPageCanonicalUrl : this.pageCMSData.residentialPageCanonicalUrl,
                    projectPageTitle: this.pageCMSData.projectPageTitle,
                    projectPageMetaKeyword: this.pageCMSData.projectPageMetaKeyword,
                    projectPageMetaDescription: this.pageCMSData.projectPageMetaDescription,
                    projectPageCanonicalUrl : this.pageCMSData.projectPageCanonicalUrl,
                    contactPageTitle: this.pageCMSData.contactPageTitle,
                    contactPageMetaKeyword: this.pageCMSData.contactPageMetaKeyword,
                    contactPageMetaDescription: this.pageCMSData.contactPageMetaDescription,
                    contactPageCanonicalUrl : this.pageCMSData.contactPageCanonicalUrl,
                    termsPageTitle: this.pageCMSData.termsPageTitle,
                    termsPageMetaKeyword: this.pageCMSData.termsPageMetaKeyword,
                    termsPageMetaDescription: this.pageCMSData.termsPageMetaDescription,
                    termsPageCanonicalUrl : this.pageCMSData.termsPageCanonicalUrl,
                    privacyPageTitle: this.pageCMSData.privacyPageTitle,
                    privacyPageMetaKeyword: this.pageCMSData.privacyPageMetaKeyword,
                    privacyPageMetaDescription: this.pageCMSData.privacyPageMetaDescription,
                    privacyPageCanonicalUrl : this.pageCMSData.privacyPageCanonicalUrl,
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                
                await this.postDataToBackend(config);
                await this.getCmsData(this.setURL);
                
                
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}