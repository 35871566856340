import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            url: this.$serverURL + this.$api.cms.headerFooter.headerFooterCMS,
            cmsData: {
                headerLogo: "",
                footerLogo: "",
                footerDescription: "",
                email: "",
                contactNumber: "",
                address:"",
                newsletterTitle:"",
                placeHolderText:"",
                copyRightText:"",
                headerButtonText:""
            },
            preview: {
                headerLogo: "",
                footerLogo: "",
            },
            // openingTimeKey: [],
            // openingTimeValue: [],
            // openingTimeArray: {},
            // counter: 1,
        }
    },
    async created() {
        document.title = "Platinum Landscape - Header Footer  CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                this.$swal.showLoading();
                let formData = new FormData();
                formData.append('footerDescription', this.cmsData?.footerDescription);
                formData.append('email', this.cmsData?.email);
                formData.append('copyrightText', this.cmsData?.copyrightText);
                formData.append('contactNumber', this.cmsData?.contactNumber);
                formData.append('address', this.cmsData?.address);
                formData.append('newsletterTitle', this.cmsData?.newsletterTitle);
                formData.append('placeHolderText', this.cmsData?.placeHolderText);
                formData.append('copyRightText', this.cmsData?.copyRightText);
                formData.append('headerButtonText', this.cmsData?.headerButtonText);
                // formData.append('newsletterTitle', JSON.stringify(this.newsletterTitleArray));
                formData.append('headerLogo', this.cmsData?.headerLogo);
                formData.append('footerLogo', this.cmsData?.footerLogo);
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        }
    }
}