<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Project Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Project Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Project Page CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Heading:</label>
                                    <input type="text" v-model="cmsData.heading" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Background Image:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.image"
                                            :src="preview.image" height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.backgroundImage"
                                            :src="cmsData.backgroundImage" height="70" />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 col-6">
                                <input class="form-control mt-3" type="file" ref="image" accept="image/*"
                                    @change="handleUploadImage('backgroundImage', 'image', 'image', $event)" />
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.icon"
                                            :src="preview.icon" height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.icon"
                                            :src="cmsData.icon" height="70" />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 col-6">
                                <input class="form-control mt-3" type="file" ref="icon" accept="image/*"
                                    @change="handleUploadImage('icon', 'icon', 'icon', $event)" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/project-page-banner-cms.js"></script>