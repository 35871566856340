import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.cms.home.heroSection.sliders,
            singleAPI: this.$serverURL + this.$api.cms.home.heroSection.singleSlider,
            title: "",
            image: "",
            previewImage: "",
            previewIcon: "",
            description: "",
            buttonText:"",
            buttonLink:"",
            buttonIcon:"",
        }

    },
    async mounted() {
    },
    methods: {
        uploadSliderImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadButtonIcon: function (event) {
            this.buttonIcon = event.target.files[0];
            let input = this.$refs.buttonIcon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewIcon = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add slider title."
                })
                return false;
            } else if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add slider image."
                })
                return false;
            } else if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add slider description."
                })
                return false;
            }else if (!this.buttonText) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add slider button text."
                })
                return false;
            }else if (!this.buttonLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add slider button link."
                })
                return false;
            }
            return true;
        },
        addSlider: async function () {
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append('title', this.title)
                    formData.append('image', this.image);
                    formData.append('description', this.description);
                    formData.append('buttonText', this.buttonText);
                    formData.append('buttonLink', this.buttonLink);
                    formData.append('buttonIcon', this.buttonIcon);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$router.push("/sliders");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        },
    }
}