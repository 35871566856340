<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">About Section CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">heading:</label>
                                    <input type="text" v-model="cmsData.heading" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Title:</label>
                                    <input type="text" v-model="cmsData.title" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Description:</label>
                                    <ckeditor :editor="editor" v-model="cmsData.description" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Button Text:</label>
                                    <input type="text" v-model="cmsData.buttonText" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Button Link:</label>
                                    <input type="text" v-model="cmsData.buttonLink" class="form-control">
                                </div>
                            </div>
                        </div>
                        <hr style="width: 100%; height:2px;"/>
                        <!-- <div class="border border-3 p-4 rounded mb-3">
                            <div class="row mb-3" v-for="index in counter" :key="index">
                                <div class="col-sm-6">
                                    <label class="form-label">Opening Time:</label>
                                    <div class="d-flex gap-2">
                                        <input type="text" class="form-control" v-model="openingTimeValue[index - 1]" />
                                        <button v-if="counter > 1" @click="deleteOpeningTimeRow(index - 1)" type="button"
                                            class="btn btn-danger">X</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <button type="button" @click="addOpeningTime()" class="btn btn-primary">Add More</button>
                            </div>
                        </div> -->
                        <div>
                            <div class="row">
                                <div class="col-lg-6 mt-3">
                                    <div class="mb-3">
                                        <label class="form-label">Time Title:</label>
                                        <input type="text" v-model="cmsData.timeTitle" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 mt-3">
                                    <div class="col-12">
                                        <label class="form-label">
                                           Time Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.timeIcon" :src="preview.timeIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.timeIcon"
                                            :src="cmsData.timeIcon" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="timeIcon" accept="image/*"
                                            @change="handleUploadImage('timeIcon', 'timeIcon', 'timeIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Service Title:</label>
                                        <input type="text" v-model="cmsData.serviceTitle" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                           Service Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.serviceIcon" :src="preview.serviceIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.serviceIcon"
                                            :src="cmsData.serviceIcon" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="serviceIcon" accept="image/*"
                                            @change="handleUploadImage('serviceIcon', 'serviceIcon', 'serviceIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Support Title:</label>
                                        <input type="text" v-model="cmsData.supportTitle" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                           Support Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.supportIcon" :src="preview.supportIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.supportIcon"
                                            :src="cmsData.supportIcon" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="supportIcon" accept="image/*"
                                            @change="handleUploadImage('supportIcon', 'supportIcon', 'supportIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/about-section-cms.js"></script>