
export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.welcomePopupCms,
            cmsData: {
                title: "",
                text1: "",
                text2: "",
                text3: "",
                buttonText: "",
                buttonLink: "",
            },
            preview: {
                image: "",
                logo: ""
            },
            
            pages: [
                {
                    value: "/",
                    label: "Home Page"
                },
                {
                    value: "/about",
                    label: "About Us Page"
                },
                {
                    value: "/residential-landscape",
                    label: "Residential Page"
                },
                {
                    value: "/commercial-landscape",
                    label: "Commercial Page"
                },
                {
                    value: "/project",
                    label: "Project Page"
                },
                {
                    value: "/contact",
                    label: "Contact Page"
                },
                {
                    value: "/terms-condition",
                    label: "Terms and Conditions"
                },
                {
                    value: "/privacy-policy",
                    label: "Privacy Policy"
                },
            ],
            selectedPages: [],
        }
    },
    async created() {
        document.title = "Platinum Landscape - Welcome Popup CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                    this.selectedPages = this.cmsData?.pages ? JSON.parse(this.cmsData?.pages) : [];
                }   
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                // this.getCoordinates();
                this.$swal.showLoading();
                let formData = new FormData();
                formData.append('title', this.cmsData?.title);
                formData.append('text1', this.cmsData?.text1);
                formData.append('text2', this.cmsData?.text2);
                formData.append('text3', this.cmsData?.text3);
                formData.append('buttonText', this.cmsData?.buttonText);
                formData.append('buttonLink', this.cmsData?.buttonLink);
                formData.append('image', this.cmsData?.image);
                formData.append('logo', this.cmsData?.logo);
                formData.append('time', this.cmsData.time);
                formData.append('expireTime', this.cmsData.expireTime);
                formData.append('pages', JSON.stringify(this.selectedPages));
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

    }
}