import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";

//CMS
import ContactUsCMS from "../components/CMS/contact-us/template/ContactUsCMS";
import PrivacyPageCMS from "../components/CMS/privacy-policy/template/PrivacyPageCMS";
import TermsPageCMS from "../components/CMS/terms-and-conditions/template/TermsPageCMS";
import AdventureSectionCMS from "../components/CMS/adventure-section-cms/template/AdventureSectionCMS.vue";
import HeaderFooterCMS from "../components/CMS/header-footer-cms/template/HeaderFooterCMS.vue";
import OurServicesSectionCMS from "../components/CMS/home-page/our-services-section-cms/template/OurServicesSectionCMS";
import PopularServicesSectionCMS from "../components/CMS/home-page/popular-services-section-cms/template/PopularServicesSectionCMS";
import HeroSectionCMS from "../components/CMS/home-page/hero-section-cms/template/HeroSectionCMS";
import AboutSectionCMS from "../components/CMS/home-page/about-section-cms/template/AboutSectionCMS";
import CreateEnvironmentSectionCMS from "../components/CMS/create-environment-section-cms/template/CreateEnvironmentSectionCMS";
import ProjectSectionCMS from "../components/CMS/project-section-cms/template/ProjectSectionCMS";
import ExperienceSectionCMS from "../components/CMS/experience-section-cms/template/ExperienceSectionCMS";
import StatisticsCMS from "../components/CMS/statistics-cms/template/StatisticsCMS";
import TestimonialCMS from "../components/CMS/testimonial-cms/template/TestimonialCMS";
import QuoteFormSectionCMS from "../components/CMS/quote-form-section-cms/template/QuoteFormSectionCMS";
import QuoteBannerSectionCMS from "../components/CMS/quote-banner-section-cms/template/QuoteBannerSectionCMS";
import CommercialPageBannerCms from "../components/CMS/commercial-page-banner-cms/template/CommercialPageBannerCms";
import ProjectPageBannerCms from "../components/CMS/project-page-banner-cms/template/ProjectPageBannerCms";
import ResidentialPageBannerCms from "../components/CMS/residential-page-banner-cms/template/ResidentialPageBannerCms";
import ResidentialPageMiddleSectionCms from "../components/CMS/residential-page-middle-section-cms/template/ResidentialPageMiddleSectionCms";
import CommercialPageMiddleSectionCms from "../components/CMS/commercial-page-middle-section-cms/template/CommercialPageMiddleSectionCms";
import ContactPageBannerCms from "../components/CMS/contact-page-banner-cms/template/ContactPageBannerCms";
import AboutPageFirstSectionCms from "../components/CMS/about-page/about-page-first-section-cms/template/AboutPageFirstSectionCMS";
import AboutPageJourneySectionCms from "../components/CMS/about-page/about-page-journey-section-cms/template/AboutPageJourneySectionCMS";
import AboutPageOurTeamSectionCms from "../components/CMS/about-page/about-page-our-team-section-cms/template/AboutPageOurTeamSectionCMS";
import DesignProcessSectionCms from "../components/CMS/design-process-section-cms/template/DesignProcessSectionCms";
import ResidentialDesignProcessSectionCms from "../components/CMS/residential-design-process-section-cms/template/ResidentialDesignProcessSectionCms";
import ResidentialPageFirstSectionCms from "../components/CMS/residential-page-first-section-cms/template/ResidentialPageFirstSectionCms";
import ResidentialPageServiceSectionCms from "../components/CMS/residential-page-service-section-cms/template/ResidentialPageServiceSectionCMS";
import CommercialPageServiceSectionCms from "../components/CMS/commercial-page-service-section-cms/template/CommercialPageServiceSectionCMS";
import WelcomePopupCms from "../components/CMS/welcome-popup-cms/template/WelcomePopupCms";
import ThankYouPopupCms from "../components/CMS/thank-you-popup-cms/template/ThankYouPopupCms";
import ExploreOurServicesCms from "../components/CMS/explore-our-services-cms/template/ExploreOurServicesCms";
import AboutPageBannerCms from "../components/CMS/about-page/about-page-banner-cms/template/AboutPageBannerCms";
import WhyChooseSectionCms from '../components/CMS/home-page/why-choose-section-cms/template/WhyChooseSectionCMS';


// SEO Settings
import PageTitleCMS from "../components/SEO/page-titles/template/PageTitleCMS";

//Hero Section
import Sliders from "../components/CMS/home-page/home-page-hero-section/template/Sliders.vue"
import AddSlider from "../components/CMS/home-page/home-page-hero-section/template/AddSlider.vue"
import UpdateSlider from "../components/CMS/home-page/home-page-hero-section/template/UpdateSlider.vue"


//Testimonial
import Testimonial from "../components/Testimonial/template/Testimonial";

//Quotes
import Quotes from "../components/Quote/template/Quote";

//newsletter
import Newsletter from "../components/newsletter/template/Newsletter.vue"

// Category
import Category from "../components/category/template/Categories";
import AddCategory from "../components/category/template/AddCategory";
import UpdateCategory from "../components/category/template/UpdateCategory";

//Project
import AddProject from "../components/project/template/AddProject";
import UpdateProject from "../components/project/template/UpdateProject";
import ProjectList from "../components/project/template/Project";

//Location
import AddLocation from "../components/location/template/AddLocation";
import UpdateLocation from "../components/location/template/UpdateLocation";
import LocationList from "../components/location/template/Location";

//Service
import AddService from "../components/services/template/AddService";
import UpdateService from "../components/services/template/UpdateService";
import ServiceList from "../components/services/template/ServiceList.vue";

import BlogPost from "../components/BlogPost/template/BlogPost";
import AddBlogPost from "../components/BlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/BlogPost/template/UpdateBlogPost";

import SocialLinks from "../components/CMS/social-links/template/SocialLinks.vue"

import TeamMembers from "../components/TeamMembers/template/TeamMembers";
import DesignProcesses from "../components/DesignProcesses/template/DesignProcesses";
import ResidentialDesignProcesses from "../components/ResidentialDesignProcesses/template/ResidentialDesignProcesses";
import RobotsTxt from '@/components/SEO/robots-txt/template/RobotsTxt';

const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
    { path: "/", redirect: { name: 'Home' } },
    { path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },

    //CMS
    { path: "/contact-page-cms", name: "ContactUsCMS", component: ContactUsCMS, beforeEnter: guard },
    { path: "/privacy-policy-page-cms", name: "PrivacyPageCMS", component: PrivacyPageCMS, beforeEnter: guard },
    { path: "/terms-and-condition-page-cms", name: "TermsPageCMS", component: TermsPageCMS, beforeEnter: guard },
    { path: "/adventure-section-cms", name: "AdventureSectionCMS", component: AdventureSectionCMS, beforeEnter: guard },
    { path: "/header-footer-cms", name: "HeaderFooterCMS", component: HeaderFooterCMS, beforeEnter: guard },
    { path: "/our-services-section-cms", name: "OurServicesSectionCMS", component: OurServicesSectionCMS, beforeEnter: guard },
    { path: "/popular-services-section-cms", name: "PopularServicesSectionCMS", component: PopularServicesSectionCMS, beforeEnter: guard },
    { path: "/hero-section-cms", name: "HeroSectionCMS", component: HeroSectionCMS, beforeEnter: guard },
    { path: "/about-section-cms", name: "AboutSectionCMS", component: AboutSectionCMS, beforeEnter: guard },
    { path: "/create-environment-section-cms", name: "CreateEnvironmentSectionCMS", component: CreateEnvironmentSectionCMS, beforeEnter: guard },
    { path: "/project-section-cms", name: "ProjectSectionCMS", component: ProjectSectionCMS, beforeEnter: guard },
    { path: "/experience-section-cms", name: "ExperienceSectionCMS", component: ExperienceSectionCMS, beforeEnter: guard },
    { path: "/statistics-cms", name: "StatisticsCMS", component: StatisticsCMS, beforeEnter: guard },
    { path: "/testimonial-cms", name: "TestimonialCMS", component: TestimonialCMS, beforeEnter: guard },
    { path: "/quote-form-section-cms", name: "QuoteFormSectionCMS", component: QuoteFormSectionCMS, beforeEnter: guard },
    { path: "/quote-banner-section-cms", name: "QuoteBannerSectionCMS", component: QuoteBannerSectionCMS, beforeEnter: guard },
    { path: "/commercial-page-banner-cms", name: "CommercialPageBannerCms", component: CommercialPageBannerCms, beforeEnter: guard },
    { path: "/project-page-banner-cms", name: "ProjectPageBannerCms", component: ProjectPageBannerCms, beforeEnter: guard },
    { path: "/residential-page-banner-cms", name: "ResidentialPageBannerCms", component: ResidentialPageBannerCms, beforeEnter: guard },
    { path: "/residential-page-middle-section-cms", name: "ResidentialPageMiddleSectionCms", component: ResidentialPageMiddleSectionCms, beforeEnter: guard },
    { path: "/commercial-page-middle-section-cms", name: "CommercialPageMiddleSectionCms", component: CommercialPageMiddleSectionCms, beforeEnter: guard },
    { path: "/contact-page-banner-cms", name: "ContactPageBannerCms", component: ContactPageBannerCms, beforeEnter: guard },
    { path: "/about-page-first-section-cms", name: "AboutPageFirstSectionCms", component: AboutPageFirstSectionCms, beforeEnter: guard },
    { path: "/about-page-journey-section-cms", name: "AboutPageJourneySectionCms", component: AboutPageJourneySectionCms, beforeEnter: guard },
    { path: "/about-page-our-team-section-cms", name: "AboutPageOurTeamSectionCms", component: AboutPageOurTeamSectionCms, beforeEnter: guard },
    { path: "/design-process-section-cms", name: "DesignProcessSectionCms", component: DesignProcessSectionCms, beforeEnter: guard },
    { path: "/residential-design-process-section-cms", name: "ResidentialDesignProcessSectionCms", component: ResidentialDesignProcessSectionCms, beforeEnter: guard },
    { path: "/residential-page-first-section-cms", name: "ResidentialPageFirstSectionCms", component: ResidentialPageFirstSectionCms, beforeEnter: guard },
    { path: "/residential-page-service-section-cms", name: "ResidentialPageServiceSectionCms", component: ResidentialPageServiceSectionCms, beforeEnter: guard },
    { path: "/commercial-page-service-section-cms", name: "CommercialPageServiceSectionCms", component: CommercialPageServiceSectionCms, beforeEnter: guard },
    { path: "/welcome-popup-cms", name: "WelcomePopupCms", component: WelcomePopupCms, beforeEnter: guard },
    { path: "/thank-you-popup-cms", name: "ThankYouPopupCms", component: ThankYouPopupCms, beforeEnter: guard },
    { path: "/explore-our-services-cms", name: "ExploreOurServicesCms", component: ExploreOurServicesCms, beforeEnter: guard },
    { path: "/about-page-banner-cms", name: "AboutPageBannerCms", component: AboutPageBannerCms, beforeEnter: guard },
    { path: "/why-choose-section-cms", name: "WhyChooseSectionCms", component: WhyChooseSectionCms, beforeEnter: guard },

    // SEO Settings
    { path: "/page-title-cms", name: "PageTitleCMS", component: PageTitleCMS, beforeEnter: guard },
    { path: "/robots-txt", name: "Robots Txt", component: RobotsTxt, beforeEnter: guard },

    //social links
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
    
    //Quotes
    { path: "/quotes", name: "Quotes", component: Quotes, beforeEnter: guard },



    //newsletter
    { path: "/newsletter", name: "Newsletter", component: Newsletter, beforeEnter: guard },

    //Blog
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },

    //Hero Section
    { path: "/sliders", name: "Slider", component: Sliders, beforeEnter: guard },
    { path: "/add-slider", name: "AddSlider", component: AddSlider, beforeEnter: guard },
    { path: "/update-slider/:id", name: "UpdateSlider", component: UpdateSlider, beforeEnter: guard },



    // Category
    { path: "/category", name: "Category", component: Category, beforeEnter: guard },
    { path: "/add-category", name: "AddCategory", component: AddCategory, beforeEnter: guard },
    { path: "/update-category/:id", name: "UpdateCategory", component: UpdateCategory, beforeEnter: guard },

    // Project
    { path: "/add-project", name: "AddProject", component: AddProject, beforeEnter: guard },
    { path: "/update-project/:id", name: "UpdateProject", component: UpdateProject, beforeEnter: guard },
    { path: "/project-list", name: "ProjectList", component: ProjectList, beforeEnter: guard },

    // Location
    { path: "/add-location", name: "AddLocation", component: AddLocation, beforeEnter: guard },
    { path: "/update-location/:id", name: "UpdateLocation", component: UpdateLocation, beforeEnter: guard },
    { path: "/location-list", name: "LocationList", component: LocationList, beforeEnter: guard },
    
    // Service
    { path: "/add-service", name: "AddService", component: AddService, beforeEnter: guard },
    { path: "/update-service/:id", name: "UpdateService", component: UpdateService, beforeEnter: guard },
    { path: "/service-list", name: "ServiceList", component: ServiceList, beforeEnter: guard },

    //Testimonial
    { path: "/testimonial", name: "Testimonial", component: Testimonial, beforeEnter: guard },
    
    //Newsletter
    { path: "/newsletter", name: "Newsletter", component: Newsletter, beforeEnter: guard },


    { path: "/team-members", name: "TeamMembers", component: TeamMembers, beforeEnter: guard },
    { path: "/design-processes", name: "DesignProcesses", component: DesignProcesses, beforeEnter: guard },
    { path: "/residential-design-processes", name: "ResidentialDesignProcesses", component: ResidentialDesignProcesses, beforeEnter: guard },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
