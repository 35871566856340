import { authHeader } from "@/auth";

export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.cms.robots,
            robotsTxt: ""
        }
    },
    async mounted() {
        document.title = "Platinum Landscape - Page Title";
        let response = await this.getCmsData(this.setURL);
        if (response.status === 200) {
            this.robotsTxt = response.data?.data?.[0]?.robotsTxt || ""
        }
    },
    methods: {
        async update() {
            this.$swal.showLoading();
            try {
                let data = {
                    robotsTxt: this.robotsTxt
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                
                await this.postDataToBackend(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}
