import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: this.$serverURL + this.$api.project.list,

            project:{
            title: "",
            shortDescription: "",
            featuredImage: "",
            isFeatured: false,
            status:"",
            service:"",
            description1: {
                title:"",
                longDescription:"",
                image1:"",
                image2:"",
                image3:"",
                imageOneTitle : "",
                imageOneAlt : "",
                imageTwoTitle : "",
                imageTwoAlt : "",
                imageThreeTitle : "",
                imageThreeAlt : "",

            },
            description2: {
                title:"",
                longDescription:"",
                image1:"",
                image2:"",
                image3:"",
                imageOneTitle : "",
                imageOneAlt : "",
                imageTwoTitle : "",
                imageTwoAlt : "",
                imageThreeTitle : "",
                imageThreeAlt : "",
            },
            description3: {
                title:"",
                longDescription:"",
                image1:"",
                image2:"",
                image3:"",
                imageOneTitle : "",
                imageOneAlt : "",
                imageTwoTitle : "",
                imageTwoAlt : "",
                imageThreeTitle : "",
                imageThreeAlt : "",
            },
            },
            preview:{
             featuredImage: "",
            desc1Image1:"",
            desc1Image2:"",
            desc1Image3:"",
            desc2Image1:"",
            desc2Image2:"",
            desc2Image3:"",
            desc3Image1:"",
            desc3Image2:"",
            desc3Image3:"",
            },
            statusList: [
                {id:1, title: "Running"},
                {id:2, title: "Recent"}
            ],
            counterTwo: false,
            counterThree: false,
            service:{},
            serviceList:[],
            canonicalUrl : "",
            metaKeyword : "",
            metaDescription : "",
            metaTitle : ""
        }

    },
    async mounted() {
        await this.getServiceList();
    },
    methods: {
        addDescripTwo (){
            this.counterTwo = !this.counterTwo;
        },
        addDescripThree (){
            this.counterThree = !this.counterThree;
        },
        getServiceList: async function () {
            let url = this.$serverURL + this.$api.services.list;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.data;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        handleUploadImage(imageKey1, imageKey2, previewImageKey, ref, event) {
            this.project[imageKey1][imageKey2] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadFeaturedImage(imageKey, previewImageKey, ref, event) {
            this.project[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        checkValidation: function () {
            if (!this.project.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter project title!"
                });
                return false;
            }
            if (!this.project.shortDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter project description!"
                });
                return false;
            }
            if (!this.project.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            }
            if (!this.project.status) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter project status!"
                });
                return false;
            }
            if(!this.project.description1.title){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description title !"
                });
                return false;
            }

            if(!this.project.description1.longDescription){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter descriptions long description !"
                });
                return false;
            }

            if(!this.project.description1.image1){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter descriptions image one !"
                });
                return false;
            }
            if(!this.project.description1.image2){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter descriptions image two !"
                });
                return false;
            }

            if(!this.project.description1.image3){
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter descriptions image three !"
                });
                return false;
            }

            if(this.counterTwo){
                if(!this.project.description2.title){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description two's title !"
                    });
                    return false;
                }
    
                if(!this.project.description2.longDescription){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description two's long description !"
                    });
                    return false;
                }
    
                if(!this.project.description2.image1){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description two's image one !"
                    });
                    return false;
                }
    
                if(!this.project.description2.image2){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description two's image two !"
                    });
                    return false;
                }
    
                if(!this.project.description2.image3){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description two's image three !"
                    });
                    return false;
                }
            }
            
            if(this.counterThree){
                if(!this.project.description3.title){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description three's title !"
                    });
                    return false;
                }
    
                if(!this.project.description3.longDescription){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description three's long description !"
                    });
                    return false;
                }
    
                if(!this.project.description3.image1){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description three's image one !"
                    });
                    return false;
                }
    
                if(!this.project.description3.image2){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description three's image two !"
                    });
                    return false;
                }
    
                if(!this.project.description3.image3){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter description three's image three !"
                    });
                    return false;
                }
            }
            if (!(this.serviceList?.find((cat)=> { 
                return cat._id == this.service?._id}))) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service!"
                });
                return false;
            }
            return true;
        },
        addProject: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("title", this.project.title);
                    formData.append("shortDescription", this.project.shortDescription);
                    formData.append("featuredImage", this.project.featuredImage);
                    formData.append("status", this.project.status.title);
                    formData.append("isFeatured", this.project.isFeatured ? true : false);
                    formData.append("description1.title", this.project.description1.title);
                    formData.append("canonicalUrl", this.canonicalUrl);
                    formData.append("metaKeyword", this.metaKeyword);
                    formData.append("metaDescription", this.metaDescription);
                    formData.append("metaTitle", this.metaTitle);
                    formData.append("description1.longDescription", this.project.description1.longDescription);
                    formData.append("description1.image1", this.project.description1.image1);
                    formData.append("description1.image2", this.project.description1.image2);
                    formData.append("description1.image3", this.project.description1.image3);
                    formData.append("description1.imageOneTitle", this.project.description1.imageOneTitle);
                    formData.append("description1.imageOneAlt", this.project.description1.imageOneAlt);
                    formData.append("description1.imageTwoTitle", this.project.description1.imageTwoTitle);
                    formData.append("description1.imageTwoAlt", this.project.description1.imageTwoAlt);
                    formData.append("description1.imageThreeTitle", this.project.description1.imageThreeTitle);
                    formData.append("description1.imageThreeAlt", this.project.description1.imageThreeAlt);
                    formData.append("serviceId", this.service._id);
                    if(this.counterTwo){
                    formData.append("description2.title", this.project.description2.title);
                    formData.append("description2.longDescription", this.project.description2.longDescription);
                    formData.append("description2.image1", this.project.description2.image1);
                    formData.append("description2.image2", this.project.description2.image2);
                    formData.append("description2.image3", this.project.description2.image3);
                    formData.append("description2.imageOneTitle", this.project.description2.imageOneTitle);
                    formData.append("description2.imageOneAlt", this.project.description2.imageOneAlt);
                    formData.append("description2.imageTwoTitle", this.project.description2.imageTwoTitle);
                    formData.append("description2.imageTwoAlt", this.project.description2.imageTwoAlt);
                    formData.append("description2.imageThreeTitle", this.project.description2.imageThreeTitle);
                    formData.append("description2.imageThreeAlt", this.project.description2.imageThreeAlt);
                    }
                    if(this.counterThree){
                    formData.append("description3.title", this.project.description3.title);
                    formData.append("description3.longDescription", this.project.description3.longDescription);
                    formData.append("description3.image1", this.project.description3.image1);
                    formData.append("description3.image2", this.project.description3.image2);
                    formData.append("description3.image3", this.project.description3.image3);
                    formData.append("description3.imageOneTitle", this.project.description3.imageOneTitle);
                    formData.append("description3.imageOneAlt", this.project.description3.imageOneAlt);
                    formData.append("description3.imageTwoTitle", this.project.description3.imageTwoTitle);
                    formData.append("description3.imageTwoAlt", this.project.description3.imageTwoAlt);
                    formData.append("description3.imageThreeTitle", this.project.description3.imageThreeTitle);
                    formData.append("description3.imageThreeAlt", this.project.description3.imageThreeAlt);
                    }  
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$swal.hideLoading();
                    this.$router.push("/project-list");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        }
    },
}