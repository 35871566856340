<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">About</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/about"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">First Section CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Image:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.image" :src="preview.image"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.image"
                                            :src="cmsData.image" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="image" accept="image/*"
                                            @change="handleUploadImage('image', 'image', 'image', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Title:</label>
                                    <input type="text" v-model="cmsData.title" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Short Description:</label>
                                    <ckeditor :editor="editor" v-model="cmsData.shortDescription" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Long Description:</label>
                                    <ckeditor :editor="editor" v-model="cmsData.longDescription" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Experience Image:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.smallImage" :src="preview.smallImage"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.smallImage"
                                            :src="cmsData.smallImage" height="70" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="smallImage" accept="image/*"
                                            @change="handleUploadImage('smallImage', 'smallImage', 'smallImage', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Point One:</label>
                                    <input type="text" v-model="cmsData.point1" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Point Two:</label>
                                    <input type="text" v-model="cmsData.point2" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Point Three:</label>
                                    <input type="text" v-model="cmsData.point3" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Point Four:</label>
                                    <input type="text" v-model="cmsData.point4" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Point Five:</label>
                                    <input type="text" v-model="cmsData.point5" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/about-page-first-section-cms.js"></script>