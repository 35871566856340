<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Statistics CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Heading:</label>
                                    <input type="text" v-model="cmsData.heading" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Background Image:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.backgroundImage" :src="preview.backgroundImage"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.backgroundImage"
                                            :src="cmsData.backgroundImage" height="80" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="backgroundImage" accept="image/*"
                                            @change="handleUploadImage('backgroundImage', 'backgroundImage', 'backgroundImage', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement One Title:</label>
                                    <input type="text" v-model="cmsData.achievementOneTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement One Value:</label>
                                    <input type="text" v-model="cmsData.achievementOneValue" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Achievement One Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.achievementOneIcon" :src="preview.achievementOneIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.achievementOneIcon"
                                            :src="cmsData.achievementOneIcon" height="80" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="achievementOneIcon" accept="image/*"
                                            @change="handleUploadImage('achievementOneIcon', 'achievementOneIcon', 'achievementOneIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Two Title:</label>
                                    <input type="text" v-model="cmsData.achievementTwoTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Two Value:</label>
                                    <input type="text" v-model="cmsData.achievementTwoValue" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Achievement Two Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.achievementTwoIcon" :src="preview.achievementTwoIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.achievementTwoIcon"
                                            :src="cmsData.achievementTwoIcon" height="80" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="achievementTwoIcon" accept="image/*"
                                            @change="handleUploadImage('achievementTwoIcon', 'achievementTwoIcon', 'achievementTwoIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Three Title:</label>
                                    <input type="text" v-model="cmsData.achievementThreeTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Three Value:</label>
                                    <input type="text" v-model="cmsData.achievementThreeValue" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Achievement Three Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.achievementThreeIcon" :src="preview.achievementThreeIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.achievementThreeIcon"
                                            :src="cmsData.achievementThreeIcon" height="80" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="achievementThreeIcon" accept="image/*"
                                            @change="handleUploadImage('achievementThreeIcon', 'achievementThreeIcon', 'achievementThreeIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Four Title:</label>
                                    <input type="text" v-model="cmsData.achievementFourTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Four Value:</label>
                                    <input type="text" v-model="cmsData.achievementFourValue" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-12">
                                        <label class="form-label">
                                            Achievement Four Icon:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img style="position: relative; left: 11px" v-if="preview.achievementFourIcon" :src="preview.achievementFourIcon"
                                            height="70" />
                                        <img style="position: relative; left: 11px" v-else-if="cmsData.achievementFourIcon"
                                            :src="cmsData.achievementFourIcon" height="80" />
                                    </div>
                                    <div class="mb-3 col-12">
                                        <input class="form-control mt-3" type="file" ref="achievementFourIcon" accept="image/*"
                                            @change="handleUploadImage('achievementFourIcon', 'achievementFourIcon', 'achievementFourIcon', $event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/statistics-cms.js"></script>