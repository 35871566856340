<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="pageCMSData.homePageTitle" class="form-control" placeholder="Home page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Canonical :</label>
                                                <textarea v-model="pageCMSData.homePageCanonicalUrl" class="form-control" placeholder="Home page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Title:</label>
                                                <input type="text" v-model="pageCMSData.aboutPageTitle" class="form-control" placeholder="About page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.aboutPageMetaKeyword" class="form-control" placeholder="About page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.aboutPageMetaDescription" class="form-control" placeholder="About page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Canonical:</label>
                                                <textarea v-model="pageCMSData.aboutPageCanonicalUrl" class="form-control" placeholder="About page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Commercial Page Title:</label>
                                                <input type="text" v-model="pageCMSData.commercialPageTitle" class="form-control" placeholder="Commercial page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Commercial Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.commercialPageMetaKeyword" class="form-control" placeholder="Commercial page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Commercial Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.commercialPageMetaDescription" class="form-control" placeholder="Commercial page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Commercial Page Canonical:</label>
                                                <textarea v-model="pageCMSData.commercialPageCanonicalUrl" class="form-control" placeholder="Commercial page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Residential Page Title:</label>
                                                <input type="text" v-model="pageCMSData.residentialPageTitle" class="form-control" placeholder="Commercial page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Residential Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.residentialPageMetaKeyword" class="form-control" placeholder="Commercial page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Residential Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.residentialPageMetaDescription" class="form-control" placeholder="Commercial page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Residential Page Canonical:</label>
                                                <textarea v-model="pageCMSData.residentialPageCanonicalUrl" class="form-control" placeholder="Commercial page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Project Page Title:</label>
                                                <input type="text" v-model="pageCMSData.projectPageTitle" class="form-control" placeholder="Project page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Project Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.projectPageMetaKeyword" class="form-control" placeholder="Project page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Project Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.projectPageMetaDescription" class="form-control" placeholder="Project page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Project Page Canonical:</label>
                                                <textarea v-model="pageCMSData.projectPageCanonicalUrl" class="form-control" placeholder="Project page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Title:</label>
                                                <input type="text" v-model="pageCMSData.contactPageTitle" class="form-control" placeholder="Contact page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaKeyword" class="form-control" placeholder="Contact page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaDescription" class="form-control" placeholder="Contact page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Canonical:</label>
                                                <textarea v-model="pageCMSData.contactPageCanonicalUrl" class="form-control" placeholder="Contact page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms and Conditions Page Title:</label>
                                                <input type="text" v-model="pageCMSData.termsPageTitle" class="form-control" placeholder="Terms and Conditions page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms and Conditions Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaKeyword" class="form-control" placeholder="Terms and Conditions page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms and Conditions Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaDescription" class="form-control" placeholder="Terms and Conditions page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms and Conditions Page Canonical:</label>
                                                <textarea v-model="pageCMSData.termsPageCanonicalUrl" class="form-control" placeholder="Terms and Conditions page Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Canonical Url:</label>
                                                <textarea v-model="pageCMSData.privacyPageCanonicalUrl" class="form-control" placeholder="Privacy page meta Canonical"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/page-title-cms.js"></script>