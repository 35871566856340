<template>
  <div class="mb-2">
    <label class="text-sm md:text-base mb-1">
      {{ label }} <span v-if="required" class="text-red-600">*</span>
    </label>
  </div>
  <div>
    <p class="bg-info text-white px-1 py-1 text-xs rounded-2 mb-0">Note: Editor Takes some time to load</p>
  </div>
  <Editor
      :api-key="apiKey"
      :init="editorInit"
      v-model="internalValue"
  />
</template>

<script src='../js/tiny-mce-editor.js'></script>
