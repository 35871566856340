<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Hero Section Slider CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Hero Section Slider CMS</h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Heading:</label>
                                <input type="text" v-model="cmsData.heading" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Title:</label>
                                <input type="text" v-model="cmsData.title" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Description</label>
                                <ckeditor :editor="editor" v-model="cmsData.description" :config="editorConfig">
                                </ckeditor>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Button Text:</label>
                                <input type="text" v-model="cmsData.buttonText" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Button Link:</label>
                                <input type="text" v-model="cmsData.buttonLink" class="form-control">
                            </div>
                        </div>
                        <div>
                            <div class="col-lg-6">
                                <div class="col-12">
                                    <label class="form-label">
                                        Background Image:
                                    </label>
                                </div>
                                <div class="col-12">
                                    <img style="position: relative; left: 11px" v-if="preview.backgroundImage"
                                        :src="preview.backgroundImage" height="70" />
                                    <img style="position: relative; left: 11px" v-else-if="cmsData.backgroundImage"
                                        :src="cmsData.backgroundImage" height="70" />
                                </div>
                                <div class="mb-3 col-12">
                                    <input class="form-control mt-3" type="file" ref="backgroundImage" accept="image/*"
                                        @change="handleUploadImage('backgroundImage', 'backgroundImage', 'backgroundImage', $event)" />
                                </div>
                            </div>


                            <div class="col-sm-6">
                                <label for="inputCollection" class="form-label">Upload Type:</label>
                                <select v-model="uploadType" class="form-select form-control mb-3">
                                    <option class="p-1" value="image">
                                        Image
                                    </option>
                                    <option class="p-1" value="video">
                                        Video
                                    </option>
                                </select>
                            </div>

                            <div class="col-6 rounded" v-if="uploadType === 'image'">
                                <div class="mb-3">
                                    <label class="form-label">Image: <strong>[Preferred Image Size: 1440X2114, Max
                                            Upload Limit:
                                            1MB]</strong></label>
                                    <input class="form-control mb-2" type="file" name="image" id="image" ref="image"
                                        aria-describedby="imageHelp" accept="image/*" @change="uploadMediaImage" />
                                    <img v-if="preview.image" :src="preview.image" height="80" />
                                    <img v-else src="/core/assets/images/400.png" height="80" />
                                </div>
                            </div>

                            <div class="col-6 rounded mb-4" v-if="uploadType === 'video'">
                                <label for="video" class="mt-3">Product Video:
                                    <strong>[Max Upload Limit:
                                        10MB]</strong></label>
                                <input class="form-control mt-3" type="file" ref="video" accept="video/*"
                                    @change="uploadVideo('video', 'video', 'video', $event)" />
                                <div class="row mt-3">
                                    <video width="320" height="240" v-if="preview.video" controls>
                                        <source :src="preview.video" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="d-grid">
                                <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/hero-section-cms.js"></script>