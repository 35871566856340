

export default {
    data() {
        return {
            
            url: this.$serverURL + this.$api.cms.commercialPageServiceSectionCms,
            cmsData: {
                imageOne: "",
                imageTwo: "",
                title: "",
                buttonText: "",
                buttonLink: "",
            },
            preview: {
                imageOne: "",
                imageTwo: "",
            }
        }
    },
    async created() {
        document.title = "Platinum Landscape - Commercial Page Service Section CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                    
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                this.$swal.showLoading();
                let formData = new FormData();
                formData.append('title', this.cmsData?.title);
                formData.append('buttonText', this.cmsData?.buttonText);
                formData.append('buttonLink', this.cmsData?.buttonLink);
                formData.append('imageOne', this.cmsData?.imageOne);
                formData.append('imageTwo', this.cmsData?.imageTwo);
                let response = await this.createUpdateCMS(this.url, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        }
    }
}