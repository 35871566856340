<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Update Project
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Update Project</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Project Title:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.title" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3">
                          <div class="col-md-12">
                            <label class="form-label" for="description">Short Description:</label>
                            <ckeditor :editor="editor" v-model="singleProject.shortDescription"
                              :config="editorConfig">
                            </ckeditor>
                          </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Featured Image:
                        <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="featuredImage" id="featuredImage"
                        ref="featuredImageInput" aria-describedby="imageHelp" accept="image/*"
                        @change="uploadFeaturedImage('featuredImage','featuredImage', 'featuredImageInput', $event)" />
                      <img v-if="preview.featuredImage" :src="preview.featuredImage" height="90" />
                      <img v-else :src="singleProject.featuredImage" height="80" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="serviceList" class="form-label">Service:</label>
                      <select v-model="service" class="form-select form-control mb-3" id="serviceList">
                        <option class="p-1" v-for="(row, index) in serviceList" :key="index"
                          :value="{ _id: row._id, title: row.title }">
                          {{ row.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <label for="inputCollection" class="form-label">Status:</label>
                      <select  v-model="singleProject.status" class="form-select form-control mb-3">
                        <option  class="p-1" v-for="(row, index) in statusList" :key="index"
                          :value="{ id: row.id, title: row.title }">
                          {{ row.title }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-4">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <label for="inputProductTitle" class="form-label">Meta Title:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="metaTitle"
                        placeholder="Meta Title" />
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <label for="inputProductTitle" class="form-label">Meta Keyword:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="metaKeyword"
                        placeholder="Meta Keyword" />
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <label for="inputProductTitle" class="form-label">Meta Description</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="metaDescription"
                        placeholder="Meta Description" />
                      </div>
                      <div class="mb-6 col-sm-12 col-md-6">
                      <label for="inputProductTitle" class="form-label">Cannonical Url:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="canonicalUrl"
                        placeholder="Enter Cannonical Url" />
                    </div>
                    </div>
                    
                  </div>

                  <div class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Is Featured: </label>
                      <div class="form-check form-switch mt-1">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                          v-model="project.isFeatured" />
                      </div>
                    </div>
                  </div>
                  
                  <div class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <div class="text-center gap-2">
                        <strong>Description One</strong>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label">Title:</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="singleProject.description1.title" placeholder="Enter section title" />
                        </div>
                        <div class="row mb-4">
                          <div class="col-md-12">
                            <label class="form-label" for="description">Long Description:</label>
                            <ckeditor :editor="editor" v-model="singleProject.description1.longDescription"
                              :config="editorConfig">
                            </ckeditor>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image One:</label>
                            <input class="form-control mb-2" type="file" name="project.description1.image1" id="desc1Image1"
                              ref="desc1Image1" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('project.description1.image1', 'desc1Image1', 'desc1Image1', $event)" />
                            <img v-if="preview.desc1Image1" :src="preview.desc1Image1" width="200" height="140" />
                            <img v-else :src="singleProject.description1.image1" width="200" height="140" />
                            
                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description1.imageOneTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description1.imageOneAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image Two:</label>
                            <input class="form-control mb-2" type="file" name="desc1Image2" id="desc1Image2"
                              ref="desc1Image2" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('project.description1.image2', 'desc1Image2', 'desc1Image2', $event)" />
                            <img v-if="preview.desc1Image2" :src="preview.desc1Image2" width="200" height="140" />
                            <img v-else :src="singleProject.description1.image2" width="200" height="140" />

                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description1.imageTwoTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description1.imageTwoAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image Three:</label>
                            <input class="form-control mb-2" type="file" name="desc1Image3" id="desc1Image3"
                              ref="desc1Image3" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('project.description1.image3', 'desc1Image3', 'desc1Image3', $event)" />
                            <img v-if="preview.desc1Image3" :src="preview.desc1Image3" width="200" height="140" />
                            <img v-else :src="singleProject.description1.image3" width="200" height="140" />
                            
                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description1.imageThreeTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description1.imageThreeAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="!counterTwo" type="button" @click="addDescripTwo()" class="btn btn-primary">
                        Add More Description
                    </button>
                  </div>
                  
                  <div v-if="counterTwo || singleProject.description2.image1" class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <div class="text-center">
                        <strong>Description Two</strong>
                        <div class="text-end" style="margin-top: -20px;"> 
                          <button v-if="counterTwo" @click="addDescripTwo()" type="button" class="btn btn-danger text-end">X</button>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label">Title:</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="singleProject.description2.title" placeholder="Enter section title" />
                        </div>
                        <div class="row mb-4">
                          <div class="col-md-12">
                            <label class="form-label" for="description">Long Description:</label>
                            <ckeditor :editor="editor" v-model="singleProject.description2.longDescription"
                              :config="editorConfig">
                            </ckeditor>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image One:</label>
                            <input class="form-control mb-2" type="file" name="desc2Image1" id="desc2Image1"
                              ref="desc2Image1" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('description2', 'image1', 'desc2Image1', 'desc2Image1', $event)" />
                              <img v-if="preview.desc2Image1" :src="preview.desc2Image1" width="200" height="140" />
                              <img v-else-if="singleProject.description2.image1" :src="singleProject.description2.image1" width="200" height="140" />
                              <img v-else src="/core/assets/images/400.png" width="200" height="140" />

                              <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description2.imageOneTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description2.imageOneAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image Two:</label>
                            <input class="form-control mb-2" type="file" name="desc2Image2" id="desc2Image2"
                              ref="desc2Image2" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('description2', 'image2', 'desc2Image2', 'desc2Image2', $event)" />
                            <img v-if="preview.desc2Image2" :src="preview.desc2Image2" width="200" height="140" />
                            <img v-else-if="singleProject.description2.image2" :src="singleProject.description2.image2" width="200" height="140" />
                            <img v-else src="/core/assets/images/400.png" width="200" height="140" />

                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description2.imageTwoTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description2.imageTwoAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image Three:</label>
                            <input class="form-control mb-2" type="file" name="desc2Image3" id="desc2Image3"
                              ref="desc2Image3" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('description2', 'image3', 'desc2Image3', 'desc2Image3', $event)" />
                            <img v-if="preview.desc2Image3" :src="preview.desc2Image3" width="200" height="140" />
                            <img v-else-if="singleProject.description2.image3" :src="singleProject.description2.image3" width="200" height="140" />
                            <img v-else src="/core/assets/images/400.png" width="200" height="140" />

                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description2.imageThreeTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description2.imageThreeAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="!counterThree" type="button" @click="addDescripThree()" class="btn btn-primary">
                        Add More Description
                    </button>
                  </div>
                  
                  <div v-if="counterThree || singleProject.description3.image1" class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <div class="text-center gap-2">
                        <strong>Description Three</strong>
                        <div class="text-end" style="margin-top: -20px;"> 
                          <button v-if="counterTwo" @click="addDescripThree()" type="button" class="btn btn-danger text-end">X</button>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label">Title:</label>
                          <input type="text" class="form-control" id="inputProductTitle"
                            v-model="singleProject.description3.title" placeholder="Enter section title" />
                        </div>
                        <div class="row mb-4">
                          <div class="col-md-12">
                            <label class="form-label" for="description">Long Description:</label>
                            <ckeditor :editor="editor" v-model="singleProject.description3.longDescription"
                              :config="editorConfig">
                            </ckeditor>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image One:</label>
                            <input class="form-control mb-2" type="file" name="desc3Image1" id="desc3Image1"
                              ref="desc3Image1" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('description3', 'image1', 'desc3Image1', 'desc3Image1', $event)" />
                            <img v-if="preview.desc3Image1" :src="preview.desc3Image1" width="200" height="140" />
                            <img v-else-if="singleProject.description3.image1" :src="singleProject.description3.image1" width="200" height="140" />
                            <img v-else src="/core/assets/images/400.png" width="200" height="140" />

                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description3.imageOneTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description3.imageOneAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image Two:</label>
                            <input class="form-control mb-2" type="file" name="desc3Image2" id="desc3Image2"
                              ref="desc3Image2" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('description3', 'image2', 'desc3Image2', 'desc3Image2', $event)" />
                            <img v-if="preview.desc3Image2" :src="preview.desc3Image2" width="200" height="140" />
                            <img v-else-if="singleProject.description3.image2" :src="singleProject.description3.image2" width="200" height="140" />
                            <img v-else src="/core/assets/images/400.png" width="200" height="140" />

                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description3.imageTwoTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description3.imageTwoAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                          <div class="col-4 mb-3">
                            <label for="exampleInputEmail1">Image Three:</label>
                            <input class="form-control mb-2" type="file" name="desc3Image3" id="desc3Image3"
                              ref="desc3Image3" aria-describedby="imageHelp" accept="image/*"
                              @change="handleUploadImage('description3', 'image3', 'desc3Image3', 'desc3Image3', $event)" />
                            <img v-if="preview.desc3Image3" :src="preview.desc3Image3" width="200" height="140" />
                            <img v-else-if="singleProject.description3.image3" :src="singleProject.description3.image3" width="200" height="140" />
                            <img v-else src="/core/assets/images/400.png" width="200" height="140" />
                           
                            <div class="row mb-3">
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Title:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description3.imageThreeTitle"
                                placeholder="Featured Image Title" />
                              </div>
                              <div class="col-6">
                                <label for="inputProductTitle" class="form-label">Image Alt:</label>
                                <input type="text" class="form-control" id="inputProductTitle" v-model="singleProject.description3.imageThreeAlt"
                                placeholder="Enter Featured Image Alt" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="updateProject" class="btn btn-primary">
                        Update Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/update-project.js'></script>