<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Location
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr/>
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Location</h5>
            <hr/>
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Location Name:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="location.locationName"
                             placeholder="Enter location name"/>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Location Title:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="location.title"
                             placeholder="Enter location title"/>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <TinyMceTextEditor
                            v-model="location.shortDescription"
                            label="Short Description"
                            :required="true"
                            :height="300"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <TinyMceTextEditor
                            v-model="location.description"
                            label="Description"
                            :required="true"
                            :height="500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-4">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label">Slug:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.slug"
                               placeholder="URL Slug"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label">Meta Title:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.metaTitle"
                               placeholder="Meta Title"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label">Meta Keyword:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.metaKeyword"
                               placeholder="Meta Keyword"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label">Meta Description</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.metaDescription"
                               placeholder="Meta Description"/>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6">
                        <label for="inputProductTitle" class="form-label">Canonical Url:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.canonicalUrl"
                               placeholder="Canonical URL"/>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 mt-2">
                        <label for="exampleInputEmail1">Seo Index: </label>
                        <div class="form-check form-switch mt-1">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                 v-model="location.seoIndex"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Image:
                        <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="image" id="image"
                             ref="imageInput" aria-describedby="imageHelp" accept="image/*"
                             @change="uploadFeaturedImage('image', 'image', 'imageInput', $event)"/>
                      <img v-if="preview.image" :src="preview.image" height="90"/>
                      <img v-else src="/core/assets/images/400.png" height="80"/>
                    </div>

                    <div class="row mb-3">
                      <div class="col-6">
                        <label for="inputProductTitle" class="form-label">Image Title:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.imageTitle"
                               placeholder="Image Title"/>
                      </div>
                      <div class="col-6">
                        <label for="inputProductTitle" class="form-label">Image Alt:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.imageAlt"
                               placeholder="Enter Image Alt"/>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-4">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <label for="inputProductTitle" class="form-label">Office Hours Title:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursTitle"
                               placeholder="Office hours title"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Sunday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursSunday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Monday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursMonday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Tuesday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursTuesday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Wednesday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursWednesday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Thursday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursThursday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Friday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursFriday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                      <div class="col-sm-12 col-md-6 mb-2">
                        <label for="inputProductTitle" class="form-label fw-bold">Saturday:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.officeHoursSaturday"
                               placeholder="9:00am-5:00pm"/>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-4">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <label for="inputProductTitle" class="form-label">Location Title:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.locationTitle"
                               placeholder="Location title"/>
                      </div>
                      <div class="col-12 mb-2">
                        <label for="inputProductTitle" class="form-label">Location Address:</label>
                        <input type="text" class="form-control" id="inputProductTitle"
                               v-model="location.locationAddress"
                               placeholder="Location address"/>
                      </div>
                      <div class="col-12 mb-2">
                        <label for="inputProductTitle" class="form-label">Location Phone:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.locationPhone"
                               placeholder="Location phone"/>
                      </div>
                      <div class="col-12 row">
                        <div class="col-sm-12 col-md-6">
                          <label for="inputProductTitle" class="form-label">Latitude:</label>
                          <input type="text" class="form-control" id="inputProductTitle" v-model="location.latitude"
                                 placeholder="Latitude"/>
                        </div>
                        <div class="col-sm-12 col-md-6">
                          <label for="inputProductTitle" class="form-label">Longitude:</label>
                          <input type="text" class="form-control" id="inputProductTitle" v-model="location.longitude"
                                 placeholder="Longitude"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-4">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <label for="inputProductTitle" class="form-label">FAQ Title:</label>
                        <input type="text" class="form-control" id="inputProductTitle" v-model="location.faqTitle"
                               placeholder="FAQ title"/>
                      </div>
                      <div v-for="(item, index) in faq" :key="index" class="col-12 mb-2 row">
                        <div class="col-sm-12 col-md-6">
                          <label for="inputProductTitle" class="form-label">Question:</label>
                          <input type="text" class="form-control" id="inputProductTitle" v-model="item.question"
                                 placeholder="Question"/>
                        </div>
                        <div class="col-sm-12 col-md-5">
                          <label for="inputProductTitle" class="form-label">Answer:</label>
                          <input type="text" class="form-control" id="inputProductTitle" v-model="item.answer"
                                 placeholder="Answer"/>
                        </div>
                        <button
                            v-if="index === 0"
                            @click="handleAddFaq"
                            type="button"
                            class="col-sm-6 col-md-1 btn-primary font-22 btn rounded mt-4"
                        >
                          +
                        </button>
                        <button
                            v-if="index !== 0"
                            @click="removeFaqItems(index)"
                            type="button"
                            class="col-sm-6 col-md-1 btn-danger btn rounded mt-4"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="addLocation" class="btn btn-primary">
                        Save Location
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-location.js'></script>
